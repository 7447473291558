import React from "react";
import { Link } from "gatsby";

const buttonClasses = {
  default: ``,
  toolkit: ``,
};

export default function TitleBar({ 
  title, 
  children, 
  className = "", 
  type = "default",
  icon,
  support = "",
  toolkit,
}) {
  return (
    <header
      className={`border-b border-t md:border-t-0 border-dashed border-gray flex justify-between mx-auto py-2 md:py-4 mb-8 md:h-[106px] text-base md:text-lg md:mb-16 ${buttonClasses[type] || ""} ${className}`}
    >
        <div className="flex justify-between items-center max-w-[1200px] w-full px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-24 mx-auto transition-all duration-300">
            <div className="flex items-center">
              {toolkit && 
                <span className="flex items-center space-x-3 mr-2">
                  <Link href="/" className="text-black font-bold text-opacity-60 hover:text-red hover:text-opacity-100">
                    Toolkit
                  </Link>
                  <span className="text-black font-bold text-opacity-40">
                    &rarr;
                  </span>
                  <span>{icon}</span>
                </span>
              }
              {support && 
                <span className="flex items-center space-x-3 mr-2">
                  <span className="text-black font-bold text-opacity-60">
                    Support
                  </span>
                  <span className="text-black font-bold text-opacity-40">
                    &rarr;
                  </span>
                  <span>{icon}</span>
                </span>}
              <h3 className="text-base md:text-lg lg:text-xl m-0 font-extrabold">{title}</h3>
            </div>
            {children}
        </div>
    </header>
  );
}
