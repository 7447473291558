import React from "react";
import { Squeak } from "squeak-react";

export default function SqueakSnippet({}) {
  return (
    <div className="max-w-3xl">
      <Squeak
        apiHost='https://squeak.cloud'
        apiKey='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB4aXBrcXV2d3FhYXVudXpqb2dlIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDk3MjE3ODUsImV4cCI6MTk2NTI5Nzc4NX0.SxdOpxHjVwap7sDUptK2TFJl7WK3v3HLuKbzb0JKeKg'
        url='https://pxipkquvwqaaunuzjoge.supabase.co'
        organizationId='bc92bb89-8590-45b9-99ff-f5a5a8faa080'
      />
    </div>
  );
}
